import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"

const images = [{url:"/angela-goh-1.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Being Different</div><div className="dimensions"><span>Wood, epoxy, clay figurines</span><span>125 x 31 x 31 cm</span>
<span>Edition of 10</span></div></div>},{url:"/angela-goh-3.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Play Ball </div><div className="dimensions"><span>Clay</span><span>30 x 22 x 13 cm</span>
</div></div>}]
const images_highlight = [
  {url:"/isabel-4.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-5.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-6.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]
const images_highlight2 = [
  {url:"/isabel-7.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-1.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-2.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]

const IndexPage = () => (<div>
  <Layout>
    <SEO title="Services" />
    <div style={{ }}>
      <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"80px", textAlign:"center"}}>Privacy Policy</h1>
      <ImageRow rowType={"largeRow"} images={[{type:"text", text: <div >
    <p style={{padding:"40px"}}>Frances Keevil is committed to protecting your privacy and being compliant with EU General Data Protection Regulation (GDPR) (effective 25th May 2018). We respect any personal date you provide us and keep it safe and your personal information will never be shared with any third party. We reserve the right to update this policy as required, any changes will be advised by email however please check regularly to ensure you are happy. As you are currently on our mailing list, if you still wish to continue receiving news and updates relating to our artists, galleries and exhibitions, including receiving our invitations and newsletters, you do not need to do anything.
<br/><br/>

      <b>Information we collect:</b><br/>
          • Our email subscription collects your email address, with optional first name, surname. You can update your contact details at any time by clicking on the links in the next email sent and unsubscribe.
          <br />• When purchasing artworks, we retain your contact details, shipping and billing addresses and information relating to your purchase
          <br />• Information you provide at any event, art fair, or by subscribing to our mailing list, or contacting us directly in person, by phone or email.

<br/><br/>
      <b>Protecting your information:</b><br/>
      We are committed to ensuring your information is secure. We have suitable physical, electronic and managerial procedure to safeguard and secure your information. We will hold your information for as long as it is in our legitimate interest to do so or your information will be archived.
<br/><br/>
      <b>Age restrictions:</b><br/>
      If you are 16 or younger, please ensure you have your parent or guardian's permission before providing us with any personal information.

<br/><br/>









      <b>Links to other websites:</b><br/>
      Our website contains links to other websites, specifically our artist websites, other art galleries, social media and may also link to other related websites of interest. If you follow an external link, please note we have no control over the content of those websites and cannot be responsible for the protection if you provide any information whilst visiting those sites.
<br/><br/>
      If you would like to be removed from the Frances Keevil mailing list, please contact
       Frances Keevil direct on +61 041 821 550 or you can follow the instructions located at the bottom of the next e-mail you receive from Frances Keevil.
      Please click here if you would like to subscribe to receive email updates from Frances Keevil.
<br/><br/>
      If you have any further questions or concerns with respect to our Privacy Policy, please feel free to contact us. Should there be a material change in our information practices, we will post the policy change in our Privacy Policy on this web site.
<br/><br/>
      This privacy policy has been updated on 31/03/2021
      </p>  </div>, span:"12"}] } />
      </div>


  </Layout>

</div>
)

export default IndexPage
